import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { EmptyState, ErrorToast, FilterTagsBar, MainWrapper, SearchTagsBar, TableList, Toast } from "@ax/components";
import { IQueryValue, IRootState, ISite, FormContent, ISchema, GetFormsParams, ILanguage, FormState } from "@ax/types";
import { useBulkSelection, useCategoryColors, useModal, useToast } from "@ax/hooks";
import { appActions } from "@ax/containers/App";
import { formsActions } from "@ax/containers/Forms";
import { ITEMS_PER_PAGE } from "@ax/containers/Forms/constants";
import { getFormTemplates, getSchemaFormCategories } from "@ax/helpers";
import Summary from "./Summary";
import BulkHeader from "./BulkHeader";
import FormItem from "./FormItem";
import { TemplateModal } from "./TemplateModal";
import FormsMenu from "../FormsMenu";
import { DeleteModal, UnPublishModal } from "../atoms";
import { getAllLangFormsIds, getSortedListStatus } from "../utils";
import { useFilterQuery, useSortedListStatus } from "./hooks";

import * as S from "./style";

const FormList = (props: IUserListProps): JSX.Element => {
  const {
    currentSiteInfo,
    forms,
    summary,
    lang,
    globalLangs,
    siteLanguages,
    setHistoryPush,
    addTemplate,
    getForms,
    setCurrentFormID,
    setLanguage,
    updateFormState,
    deleteForm,
  } = props;

  const firstPage = 1;
  const isSiteView = !!currentSiteInfo;
  const BASE_URL = isSiteView ? "/sites/forms" : "/forms";
  const isEmpty = !forms || forms.length === 0;
  const formTemplates = getFormTemplates();
  const formCategories = getSchemaFormCategories();
  const featuredCategory = formCategories.find((cat) => cat.featured);
  const availableLanguages = isSiteView ? siteLanguages : globalLangs;

  const [page, setPage] = useState(firstPage);
  const [isScrolling, setIsScrolling] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ISchema | null>(null);
  const [deleteAllVersions, setDeleteAllVersions] = useState(false);
  const [arePagesTranslated, setArePagesTranslated] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { isOpen: isNewOpen, toggleModal: toggleNewModal } = useModal();
  const { isVisible, toggleToast, setIsVisible, state: toastState } = useToast();
  const { categoryColors, addCategoryColors } = useCategoryColors();
  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } = useModal();
  const { isOpen: isUnpublishOpen, toggleModal: toggleUnpublishModal } = useModal();
  const { sortedListStatus, setSortedListStatus } = useSortedListStatus();
  const { setFiltersSelection, resetFilterQuery, filterValues, query: currentFilterQuery } = useFilterQuery();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const formIds = forms.map((form) => form.id);

  const {
    resetBulkSelection,
    isSelected,
    areItemsSelected,
    checkState,
    addToBulkSelection,
    selectAllItems,
    selectedItems,
    setHoverCheck,
  } = useBulkSelection(formIds);

  const pagination = {
    setPage,
    itemsPerPage: ITEMS_PER_PAGE,
    totalItems: summary.total,
    currPage: page,
  };

  const getParams = useCallback(() => {
    const params: GetFormsParams = {
      page,
      query: searchQuery,
      filterQuery: currentFilterQuery,
    };

    return params;
  }, [page, searchQuery, currentFilterQuery]);

  useEffect(() => {
    const handleGetForms = async () => {
      const params = getParams();
      await getForms(params);
    };
    handleGetForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams, lang]);

  const onScroll = (e: any) => setIsScrolling(e.target.scrollTop > 0);

  const handleSelectAll = () => selectAllItems();

  const selectItems = () => (checkState.isAllSelected ? resetBulkSelection() : selectAllItems());

  const sortItems = async (orderPointer: IQueryValue[], isAscending: boolean) => {
    setPage(firstPage);
    const sortedState = getSortedListStatus(orderPointer[0].value.toString(), isAscending);
    setSortedListStatus(sortedState);
    setFiltersSelection("order", orderPointer, isAscending);
  };

  const filterItems = async (filterPointer: string, filtersSelected: IQueryValue[]) => {
    setPage(firstPage);
    setFiltersSelection(filterPointer, filtersSelected);
  };

  const createNewForm = () => {
    const templateSchema = selectedTemplate ? selectedTemplate : Object.values(formTemplates)[0];
    addTemplate(templateSchema.component);
    setSelectedTemplate(null);
    setHistoryPush(`${BASE_URL}/editor/new`, true);
  };

  const handleClick = (formID: number) => {
    setCurrentFormID(formID);
    setHistoryPush(`${BASE_URL}/editor`, true);
  };

  const addNewAction = () => (Object.keys(formTemplates).length > 1 ? toggleNewModal() : createNewForm());

  const handleLanguage = (language: ILanguage) => {
    const { locale, id } = language;
    setLanguage({ locale, id });
    setPage(firstPage);
    resetBulkSelection();
  };

  const publishForm = async () => {
    const isUpdated = await updateFormState(selectedItems.all, "active");
    if (isUpdated) {
      resetBulkSelection();
      toggleToast(`${selectedItems.all.length} form${selectedItems.all.length > 1 ? "s" : ""} published`);
    }
  };

  const unpublishForm = async () => {
    const isUpdated = await updateFormState(selectedItems.all, "inactive");
    if (isUpdated) {
      resetBulkSelection();
      toggleToast(`${selectedItems.all.length} form${selectedItems.all.length > 1 ? "s" : ""} unpublished`);
    }
    isUnpublishOpen && toggleUnpublishModal();
  };

  const handleDeleteForm = async () => {
    const idsToBeDeleted = getAllLangFormsIds(forms, selectedItems.all, deleteAllVersions);
    const isDeleted = await deleteForm(idsToBeDeleted);
    if (isDeleted) {
      toggleToast(`${selectedItems.all.length} form${selectedItems.all.length > 1 ? "s" : ""} deleted`);
    }
    isDeleteOpen && toggleDeleteModal();
  };

  const handleToggleBulkDelete = () => {
    const hasTranslations = forms
      .filter((form) => selectedItems.all.includes(form.id))
      .some((form) => form.dataLanguages.length > 1);
    setArePagesTranslated(hasTranslations);
    toggleDeleteModal();
  };

  const bulkActions = [
    {
      icon: "upload-pending",
      text: "publish",
      action: publishForm,
    },
    {
      icon: "offline",
      text: "unpublish",
      action: toggleUnpublishModal,
    },
    {
      icon: "delete",
      text: "delete",
      action: handleToggleBulkDelete,
    },
  ];

  const TableHeader = (
    <BulkHeader
      filterValues={filterValues}
      showBulk={areItemsSelected(formIds)}
      selectAllItems={handleSelectAll}
      totalItems={summary.total}
      selectItems={selectItems}
      checkState={checkState}
      isScrolling={isScrolling}
      sortItems={sortItems}
      filterItems={filterItems}
      sortedListStatus={sortedListStatus}
      setHoverCheck={setHoverCheck}
      featuredCategory={featuredCategory}
      bulkActions={bulkActions}
      siteID={currentSiteInfo?.id || null}
    />
  );

  const handleCloseModal = () => {
    isNewOpen && toggleNewModal();
    setSelectedTemplate(null);
  };

  const mainNewModalAction = {
    title: "Create new form",
    onClick: createNewForm,
    disabled: !selectedTemplate,
  };

  const secondaryNewModalAction = { title: "Cancel", onClick: handleCloseModal };

  const mainUnpublishModalAction = {
    title: `Unpublish form${selectedItems.all.length > 1 ? "s" : ""}`,
    onClick: unpublishForm,
  };

  const secondaryUnpublishModalAction = { title: "Cancel", onClick: toggleUnpublishModal };

  const mainDeleteModalAction = {
    title: `Delete form${selectedItems.all.length > 1 ? "s" : ""}`,
    onClick: handleDeleteForm,
  };

  const secondaryDeleteModalAction = { title: "Cancel", onClick: toggleDeleteModal };

  const rightButtonProps = {
    label: "New",
    action: addNewAction,
  };

  const emptyProps = {
    message: "To start using forms in your site, create as many forms as you need",
    button: "New form",
    action: addNewAction,
  };

  const filterLabels = {
    translated: "Language",
    type: featuredCategory?.label || "",
    state: "State",
  };

  return (
    <MainWrapper
      title="Forms"
      rightButton={rightButtonProps}
      language={lang}
      availableLanguages={availableLanguages}
      languageAction={handleLanguage}
      searchAction={setSearchQuery}
      searchValue={searchQuery}
    >
      <S.FormListWrapper ref={wrapperRef} data-testid="forms-wrapper">
        <FormsMenu />
        <S.TableListWrapper>
          <ErrorToast />
          {!isEmpty && <Summary summary={summary} filterItems={filterItems} />}
          {
            <TableList
              tableHeader={TableHeader}
              pagination={pagination}
              onScroll={onScroll}
              hasFixedHeader={true}
              tableRef={tableRef}
            >
              <>
                <S.SearchTags>
                  <SearchTagsBar query={searchQuery} setQuery={setSearchQuery} />
                  <FilterTagsBar
                    filters={filterValues}
                    setFilters={setFiltersSelection}
                    resetFilters={resetFilterQuery}
                    labels={filterLabels}
                  />
                </S.SearchTags>
                {isEmpty ? (
                  <S.EmptyWrapper data-testid="empty-wrapper">
                    <EmptyState {...emptyProps} />
                  </S.EmptyWrapper>
                ) : (
                  forms &&
                  forms.map((form) => {
                    const isItemSelected = isSelected(form.id);
                    return (
                      <FormItem
                        form={form}
                        key={form.id}
                        onClick={handleClick}
                        onChange={addToBulkSelection}
                        isSelected={isItemSelected}
                        toggleToast={toggleToast}
                        languages={availableLanguages}
                        lang={lang}
                        featuredCategory={featuredCategory}
                        categoryColors={categoryColors}
                        addCategoryColors={addCategoryColors}
                      />
                    );
                  })
                )}
              </>
            </TableList>
          }
        </S.TableListWrapper>
      </S.FormListWrapper>
      {isNewOpen && (
        <TemplateModal
          isOpen={isNewOpen}
          toggleModal={handleCloseModal}
          templates={formTemplates}
          mainModalAction={mainNewModalAction}
          secondaryModalAction={secondaryNewModalAction}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
      )}
      {isUnpublishOpen && (
        <UnPublishModal
          isOpen={isUnpublishOpen}
          toggleModal={toggleUnpublishModal}
          mainModalAction={mainUnpublishModalAction}
          secondaryModalAction={secondaryUnpublishModalAction}
          title={selectedItems.all.length > 1 ? "these forms" : "this form"}
        />
      )}
      {isDeleteOpen && (
        <DeleteModal
          isOpen={isDeleteOpen}
          toggleModal={toggleDeleteModal}
          mainModalAction={mainDeleteModalAction}
          secondaryModalAction={secondaryDeleteModalAction}
          title={selectedItems.all.length > 1 ? "these forms" : "this form"}
          {...{ isTranslated: arePagesTranslated, deleteAllVersions, setDeleteAllVersions }}
        />
      )}
      {isVisible && <Toast setIsVisible={setIsVisible} message={toastState} />}
    </MainWrapper>
  );
};

const mapStateToProps = (state: IRootState) => ({
  currentSiteInfo: state.sites.currentSiteInfo,
  forms: state.forms.forms,
  summary: state.forms.summary,
  siteLanguages: state.sites.currentSiteLanguages,
  globalLangs: state.app.globalLangs,
  lang: state.app.lang,
});

const mapDispatchToProps = {
  setHistoryPush: appActions.setHistoryPush,
  addTemplate: formsActions.addTemplate,
  getForms: formsActions.getForms,
  setCurrentFormID: formsActions.setCurrentFormID,
  setLanguage: appActions.setLanguage,
  deleteForm: formsActions.deleteForm,
  updateFormState: formsActions.updateFormState,
};

interface IDispatchProps {
  setHistoryPush(path: string, isEditor?: boolean): Promise<void>;
  addTemplate(template: string): Promise<void>;
  getForms(params?: GetFormsParams): Promise<void>;
  setCurrentFormID(formID: number | null): void;
  setLanguage(lang: { locale: string; id: number }): void;
  deleteForm: (formID: number | number[]) => Promise<boolean>;
  updateFormState(formID: number | number[], state: FormState): Promise<boolean>;
}

interface IProps {
  currentSiteInfo: ISite | null;
  forms: FormContent[];
  summary: { total: number; active: number; inactive: number };
  lang: { locale: string; id: number };
  siteLanguages: ILanguage[];
  globalLangs: ILanguage[];
}

export type IUserListProps = IProps & IDispatchProps;

export default connect(mapStateToProps, mapDispatchToProps)(FormList);
